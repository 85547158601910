.intro {
  color: '#eee';
  font-family: 'Vesper Libre';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  //border: 1px solid red;
  transform: translate(-50%, -50%);
}

.intro-inner-box {
  float: left;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

  h1, h2 {
    line-height: 1;
    font-weight: 500;
  }
  h1 {
    font-size: 73px;
  }
  h2 {
    font-size: 32px;
  }
}