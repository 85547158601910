@import 'colors';

$line-fade: 10px;

p {
  font-family: 'Vesper Libre';
  font-size: 21px;
}

.custom-h1 {
  font-family: 'Vesper Libre';
  color: $natural-white;
  font-size: 42px;
  margin-bottom: auto;
}

.custom-h2 {
  font-family: 'Vesper Libre';
  color: $natural-white;
  font-size: 28px;
  margin-bottom: auto;
}

.hexagon-edge-color {
  color: $heaxagon-edge;
}

.white-text-border {
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.black-text-border {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.dark-bg-color {
  color: $dark-title
}

.wrapper {
  display: flex;
  margin: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  @media screen and (max-width: 800px) {
    display: block;
    margin: auto;
  }
}

.skew-wrapper {
  padding-top: 3%;
  height: 30%;
  background-color: steelblue;
  display: block;
}

.full-block {
  width:100%;
  height:100%;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  p {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
}

.basic-text {
  font-size: 21px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
}

.left-margin {
  margin-left: 10%;
  width:90%;
  height:100%;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
  }
}

.right-margin {
  margin-right: 10%;
  width:90%;
  height:100%;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
  }
}

.half-block {
  width:50%;
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.third-block {
  width:33.33%;
  height:100%;
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
}

.skew-background {
  width: 100%;
  background-color: steelblue;
  transform: skewY(-10deg);
}
.restore-skew {
  transform: skewY(10deg);
}
.centralized-block {
  width:90%;
  height:100%;
  text-align: center;
  padding-top: 10px;
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 800px) {
    margin-left: 3%;
    margin-right: 3%;
    width: 94%;
  }
}

.other-block {
  width:33.3%;
  height:100%;
  text-align: center;
  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
}

.certificate {
  width: 150px;
  height: 150px;
}

.photo {
  margin-top: 5%;
  margin-bottom: 3%;
  margin-right: 50px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 94%;
  @media screen and (max-width: 800px) {
    width: 80%;
    margin: auto
  }
}

.box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 10px;
  margin: 0 auto 0 auto;
  transform-origin: bottom;
  width: 10px;
}

.bounce {
  margin-top: 20px;
  animation-name: bounce;
  animation-timing-function: ease;
}
@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

hr {
  width: 60%;
  margin-top: 0px;
}

.d-to-l-gradient {
  height: $line-fade;
  background-image:
    linear-gradient(
      $l-blue, $d-blue
    );
}

.l-to-d-gradient {
  height: $line-fade;
  background-image:
    linear-gradient(
      $d-blue, $l-blue
    );
}

.bg-to-d-gradient {
  height: $line-fade;
  background-image:
    linear-gradient(
      $bg-bottom, $l-blue
    );
}

.bg-to-l-gradient {
  height: $line-fade;
  background-image:
    linear-gradient(
      $l-blue, $bg-bottom, $d-blue
    );
}

.general-list {
  padding: 0;
}

.general-list-margin {
  margin-left: 10px;
  margin-right: 10px;
  background-color: $natural-white;
  border-radius: 5px;
  border: 2px solid #000;
}

.dot-timeline-box {
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 2px solid #000;
  background-color: $natural-white;
  @media screen and (max-width: 800px) {
    margin-right: 0px;
    margin-left: 0px;
    span {
      font-size: 14px;
    }
  }
}

.media-card {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}