$heaxagon-edge: #eb9f3c;
$bg-bottom: #33334b;
$black: #000;

$natural-white: #eeeeee;
$dark-title: #1a4e82;
$d-blue: #235a90;
$l-blue: #c1d9f5;

.d-blue {
  background-color: $d-blue;
}

.l-blue {
  background-color: $l-blue;
}

body {
  background-color: $l-blue;
}

.black-opacity-mask {
  //background-color: rgba($color: $black, $alpha: 0.00);
}