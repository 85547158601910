@import 'colors';

$hexagon-height: 90px;
$hexagon-half-height: $hexagon-height / 2;
$hexagon-width: 156px;
$hexagon-half-width: $hexagon-width / 2;

.hexagon-group {
  width:100%;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.hexagon-container {
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  // transition: margin-top 1s;
}

// .hexagon-container:hover {
//   margin-top: -50px;
// }

.hexagon {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: $heaxagon-edge;
  background-size: cover;
  margin: auto;
  height: 150px;
  width: 130px;
  display: flex;
}

.hexagon-inner {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background: $natural-white;
  background-size: cover;
  margin: auto;
  min-height: 93%;
  max-height: 93%;
  min-width: 93%; 
  max-width: 93%; 
}

.hex-text-box {
  line-height: 1;
  margin: auto;
  min-height: 50%;
  max-height: 50%;
  font-size: 16px;
  text-align: center;
  color: #545a63;
  font-weight: bold;
}